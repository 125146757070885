import './App.css';
import './Modal.css'
import React, { useState, useRef, useEffect } from 'react'
import ReactDOM from 'react-dom';
import oplatka from './images/oplatka-simple.png'
import logowhite from './images/morient-logo-white.png'
import Order from './Order';

const Modal = ({ isOpen, onClose, children }) => {
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.body.classList.add('hero-modal-open');
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.body.classList.remove('hero-modal-open');
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="hero-modal-overlay">
      <div 
        className="hero-modal-backdrop"
        onClick={onClose}
      />
      <div className="hero-modal-container">
        <button
          onClick={onClose}
          className="hero-modal-close-button"
        >
          ✕
        </button>
        <div className="hero-modal-content">
          {children}
        </div>
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

const Hero = ({ language }) => {
  const [isVertical, setIsVertical] = useState(false);
  const [isOrderOpen, setIsOrderOpen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsVertical(window.innerWidth < 768);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const content = {
    CZ: {
      headline1: "Objevte chuť",
      headline2: "lázeňských oplatek",
      description: "Ochutnejte lázeňské oplatky vyrobené z kvalitních surovin v srdci západočeského lázeňského trojúhelníku. S výběrem od lískooříškových oplatek až po čokoládové se s našimi oplatkami trefíte do chuti i těm nejvybíravějším strávníkům.",
      buttonText: "Objednejte nyní",
    },
    EN: {
      headline1: "Discover the taste",
      headline2: "of Czech spa wafers",
      description: "Try our spa wafers made from quality ingredients in the heart of the West Bohemian spa triangle. From hazelnut wafers to chocolate ones, our wafers will satisfy even the most demanding taste buds.",
      buttonText: "Order yours now",
    }
  };

  const currentContent = content[language] || content.CZ;

  return (
    <>
      <div className="hero">
        <img src={logowhite} className="logo-png" alt="Logo" />
        <div className="hero-left">
          <span className="hero-text">{currentContent.headline1}</span><br/>
          <span className="hero-text">{currentContent.headline2}</span><br/>
          <p className="hero-text-small">{currentContent.description}</p>
          <div 
            className="contact-button cursor-pointer"
            onClick={() => setIsOrderOpen(true)}
          >
            {currentContent.buttonText} <span>&gt;&gt;</span>
          </div>
        </div>
        <div className="hero-right">
          <img src={oplatka} alt="Wafer" />
        </div>
      </div>

      <Modal 
        isOpen={isOrderOpen} 
        onClose={() => setIsOrderOpen(false)}
      >
        <Order language={language} />
      </Modal>
    </>
  );
};

export default Hero;