import './App.css';
import React, { useState, useRef, useEffect } from 'react'
import { Menu, X, Globe, ChevronDown } from 'lucide-react'

function Navigation({ onLanguageChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLanguage] = useState('CZ');
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);
  const langMenuRef = useRef(null);

  const toggleMenu = () => setIsOpen(!isOpen);
  const toggleLangMenu = () => setIsLangMenuOpen(!isLangMenuOpen);

  const languages = [
    { code: 'EN', name: 'English' },
    { code: 'CZ', name: 'Česky' },
  ];
  const changeLanguage = (lang) => {
    console.log("changing lang")
    onLanguageChange(lang);
  };

  const menuItemsCZ = [
    { name: 'Výrobky', href: 'vyrobky' },
    { name: 'Kontakt', href: 'kontakt' },
  ];
  const menuItemsEN = [
    { name: 'Products', href: 'vyrobky' },
    { name: 'Contact', href: 'kontakt' },
  ];

  const handleClick = (e, href) => {
    e.preventDefault();
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (langMenuRef.current && !langMenuRef.current.contains(event.target)) {
        setIsLangMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const LanguageDropdown = ({ isMobile }) => (
    <div className={`language-dropdown ${isMobile ? 'mobile' : ''}`} ref={langMenuRef}>
      <button onClick={toggleLangMenu} className="language-button">
        <Globe size={24} strokeWidth={1} />
        <span>{language}</span>
        <ChevronDown size={16} strokeWidth={1} />
      </button>
      {isLangMenuOpen && (
        <ul className="language-menu">
          {languages.map((lang) => (
            <li key={lang.code}>
              <button onClick={() => { setLanguage(lang.code); setIsLangMenuOpen(false); changeLanguage(lang.code);}}>
                {lang.name}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );


  return (
    <div className="navigation">
      <div className="nav-container">

        {/* Mobile Menu */}
        <button className="mobile-menu-toggle" onClick={toggleMenu}>
          {isOpen ? <X size={28} strokeWidth={3} className='menu-toggle' /> : <Menu size={28} strokeWidth={3} className="menu-toggle" />}
        </button>
        {isOpen && (
          <div className="tiny-menu">
            <div className='tiny-nav-items'>
              {(language === 'CZ' ? menuItemsCZ : menuItemsEN).map((item) => (
                <a 
                  key={item.name} 
                  href={`#${item.href}`} 
                  onClick={(e) => handleClick(e, item.href)} 
                  className="tiny-nav-item"
                >
                  {item.name}
                </a>
              ))}
            </div>
          </div>
        )}

        {/* Desktop Menu */}
        <div className="desktop-menu">
          <div className='nav-items'>
            {(language === 'CZ' ? menuItemsCZ : menuItemsEN).map((item) => (
              <a 
                key={item.name} 
                href={`#${item.href}`} 
                onClick={(e) => handleClick(e, item.href)} 
                className="nav-item"
              >
                {item.name}
              </a>
            ))}
          </div>
          <LanguageDropdown isMobile={false} />
        </div>
      </div>
    </div>
  );
}

export default Navigation;
