import './App.css'
import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import prichut1 from './images/prichut1.jpg'
import prichut2 from './images/prichut2.jpg'
import prichut3 from './images/prichut3.jpg'
import prichut4 from './images/prichut4.jpg'

const ImageCarousel = ({ language }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isTinyScreen, setIsTinyScreen] = useState(false);
  const [slideDirection, setSlideDirection] = useState('');
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [loadedImages, setLoadedImages] = useState([]);

  const nextSlide = () => {
    setSlideDirection('slide-left');
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      setIsTransitioning(false);
    }, 300);
  };

  const prevSlide = () => {
    setSlideDirection('slide-right');
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
      setIsTransitioning(false);
    }, 300);
  };

  const getSlideIndex = (index) => {
    return (index + images.length) % images.length;
  };

  const translations = {
    CZ: {
      images1: "Oplatky s lískooříškovou příchutí <si>(150g)</si>",
      images2: "Oplatky s mandlovou příchutí <si>(150g)</si>",
      images3: "Oplatky s vanilkovou příchutí <si>(150g)</si>",
      images4: "Oplatky s čokoládovou příchutí <si>(175g)</si>"
    },
    EN: {
      images1: "Wafers with hazelnut filling <si>(150g)</si>",
      images2: "Wafers with almond filling <si>(150g)</si>",
      images3: "Wafers with vanilla filling <si>(150g)</si>",
      images4: "Wafers with chocolate filling <si>(175g)</si>"
    }
  };
  const textDescriptions = {
    CZ: {
      images1: "Šest kusů křehkých lázeňských oplatek s lískovými oříšky; skvělého výrobku, který  se stal symbolem lázní. Lískové oříšky spolu s vanilkou a skořicí vytváří jedinečnou chuť. <i>Oplatky jsou bez palmového oleje.</i>",
      images2: "Šest kusů křehkých lázeňských oplatek s mandlemi. <i>Oplatky jsou bez palmového oleje.</i>",
      images3: "Šest kusů křehkých lázeňských oplatek provoněných vanilkou. <i>Oplatky jsou bez palmového oleje.</i>",
      images4: "Čtyři kusy křehkých lázeňských oplatek promazané silnou vrstvou lahodné mléčné čokolády. <i>Oplatky jsou bez palmového oleje.</i>"
    },
    EN: {
      images1: "Six pieces of delicate spa wafers with hazelnuts; an excellent product that has become a symbol of the spa. Hazelnuts combined with vanilla and cinnamon create a unique taste. <i>The wafers are palm oil free.</i>",
      images2: "Six pieces of delicate spa wafers with almonds. <i>The wafers are palm oil free.</i>",
      images3: "Six pieces of delicate spa wafers scented with vanilla. <i>The wafers are palm oil free.</i>",
      images4: "Four pieces of delicate spa wafers layered with a thick coating of delicious milk chocolate. <i>The wafers are palm oil free.</i>"
    }
  }
  const textIngredients = {
    CZ: {
      images1: "<b>Složení:</b> 60% náplň s lískooříškovou příchutí (cukr, rostlinný tuk (kokosový), 5%  <bi>mletá jádra lískových oříšků</bi>, kukuřičná vláknina, skořice, aroma), <bi>pšeničná mouka</bi>, řepkový olej, <bi>pšeničný škrob</bi>, <bi>sušené vaječné žloutky</bi>, emulgátor: slunečnicový lecitin, jedlá sůl.",
      images2: "<b>Složení:</b> 60% náplň s mandlovou příchutí (cukr, rostlinný tuk (kokosový), 5%  <bi>mleté mandle</bi>, kukuřičná vláknina, skořice, aroma), <bi>pšeničná mouka</bi>, řepkový olej, <bi>pšeničný škrob</bi>, <bi>sušené vaječné žloutky</bi>, emulgátor: slunečnicový lecitin, jedlá sůl.",
      images3: "<b>Složení:</b> 60% náplň s vanilkovou příchutí (cukr, rostlinný tuk (kokosový), aroma), <bi>pšeničná mouka</bi>, řepkový olej, <bi>pšeničný škrob</bi>, <bi>sušené vaječné žloutky</bi>, emulgátor: slunečnicový lecitin, jedlá sůl.",
      images4: "<b>Složení:</b> 72,5% náplň s čokoládovou příchutí (rostlinný tuk (kokosový), cukr, kakový prášek, <bi>sušené mléko</bi>, <bi>laktóza (z mléka)</bi>, přírodní vanilková příchuť), <bi>pšeničná mouka</bi>, řepkový olej, kakaový prášek, <bi>sušené mléko</bi>, <bi>sušené vaječné žloutky</bi>, emulgátor: slunečnicový lecitin, jedlá sůl."
    },
    EN: {
      images1: "<b>Ingredients:</b> 60% hazelnut flavored filling (sugar, vegetable fat (coconut), 5% <bi>ground hazelnuts</bi>, corn fiber, cinnamon, flavoring), <bi>wheat flour</bi>, rapeseed oil, <bi>wheat starch</bi>, <bi>dried egg yolks</bi>, emulsifier: sunflower lecithin, salt.",
      images2: "<b>Ingredients:</b> 60% almond flavored filling (sugar, vegetable fat (coconut), 5% <bi>ground almonds</bi>, corn fiber, cinnamon, flavoring), <bi>wheat flour</bi>, rapeseed oil, <bi>wheat starch</bi>, <bi>dried egg yolks</bi>, emulsifier: sunflower lecithin, salt.",
      images3: "<b>Ingredients:</b> 60% vanilla flavored filling (sugar, vegetable fat (coconut), flavoring), <bi>wheat flour</bi>, rapeseed oil, <bi>wheat starch</bi>, <bi>dried egg yolks</bi>, emulsifier: sunflower lecithin, salt.",
      images4: "<b>Ingredients:</b> 72.5% chocolate flavored filling (vegetable fat (coconut), sugar, cocoa powder, <bi>dried milk</bi>, <bi>lactose (from milk)</bi>, natural vanilla flavoring), <bi>wheat flour</bi>, rapeseed oil, cocoa powder, <bi>dried milk</bi>, <bi>dried egg yolks</bi>, emulsifier: sunflower lecithin, salt."
    }
  }
  const images = [
    { src: prichut1, alt: "Image 1", text: translations[language].images1, description: textDescriptions[language].images1, ingredients: textIngredients[language].images1 },
    { src: prichut2, alt: "Image 2", text: translations[language].images2, description: textDescriptions[language].images2, ingredients: textIngredients[language].images2 },
    { src: prichut3, alt: "Image 3", text: translations[language].images3, description: textDescriptions[language].images3, ingredients: textIngredients[language].images3 },
    { src: prichut4, alt: "Image 4", text: translations[language].images4, description: textDescriptions[language].images4, ingredients: textIngredients[language].images4 },
  ];

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 1440);
      setIsTinyScreen(window.innerWidth < 800);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  useEffect(() => {
    let intervalId;
    if (isTinyScreen) {
      intervalId = setInterval(() => {
        nextSlide();
      }, 3000);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isTinyScreen, currentIndex]);

  useEffect(() => {
    // Preload images
    images.forEach((image) => {
      const img = new Image();
      img.src = image.src;
      img.onload = () => {
        setLoadedImages((prev) => [...prev, image.src]);
      };
    });
  }, []);

  const renderImage = (src, alt, className) => {
    if (loadedImages.includes(src)) {
      return (
        <img
          src={src}
          alt={alt}
          className={className}
          style={{ opacity: 1, transition: 'opacity 0.3s ease-in-out' }}
        />
      );
    } else {
      return <div className={`image-placeholder ${className}`}></div>;
    }
  };

  const handleDotClick = (index) => {
    if (index === currentIndex) return;
    
    setSlideDirection(index > currentIndex ? 'slide-left' : 'slide-right');
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex(index);
      setIsTransitioning(false);
    }, 300);
  };

  const parseFormattedText = (text) => {
    if (!text) return null;
    
    // First split by italic tags
    const italicParts = text.split(/(<i>.*?<\/i>)/);
    
    return italicParts.map((part, index) => {
      if (part.startsWith('<i>') && part.endsWith('</i>')) {
        // Handle italic text
        const italicText = part.slice(3, -4);
        
        // Check for bold tags within italic text
        const boldParts = italicText.split(/(<b>.*?<\/b>)/);
        return <i key={`i-${index}`}>
          {boldParts.map((boldPart, boldIndex) => {
            if (boldPart.startsWith('<b>') && boldPart.endsWith('</b>')) {
              return <b key={`b-${boldIndex}`}>{boldPart.slice(3, -4)}</b>;
            }
            return boldPart;
          })}
        </i>;
      } else {
        // Handle regular text, bold tags, and bi tags
        const parts = part.split(/(<b>.*?<\/b>)|(<bi>.*?<\/bi>)|(<si>.*?<\/si>)/);
        return parts.map((subPart, boldIndex) => {
          if (subPart?.startsWith('<b>') && subPart?.endsWith('</b>')) {
            return <b key={`b-${index}-${boldIndex}`}>{subPart.slice(3, -4)}</b>;
          }
          if (subPart?.startsWith('<bi>') && subPart?.endsWith('</bi>')) {
            return <span key={`bi-${index}-${boldIndex}`} className="bi-text">
              {subPart.slice(4, -5)}
            </span>;
          }
          if (subPart?.startsWith('<si>') && subPart?.endsWith('</si>')) {
            return <span key={`si-${index}-${boldIndex}`} className="si-text">
              {subPart.slice(4, -5)}
            </span>;
          }
          return subPart;
        });
      }
    });
  };

  return (
    <div className="carousel-parent">
      <div className="carousel-container">
        <div className={`carousel-images ${slideDirection}`}>
          { isSmallScreen ? (
            <div className={`carousel-image center ${isTransitioning ? 'transitioning' : ''}`}>
              {renderImage(images[currentIndex].src, images[currentIndex].alt, "round")}
            </div>
          ) : (
          [-1, 0, 1].map((offset) => {
            const index = getSlideIndex(currentIndex + offset);
            return (
              <div
                key={index}
                className={`carousel-image ${offset === 0 ? 'center' : 'adjacent'}`}
              >
                {renderImage(images[index].src, images[index].alt, offset === 0 ? "round" : "")}
              </div>
            );
          })
        )}
        </div>
  
        {!isTinyScreen && (
          <>
            <button
              onClick={prevSlide}
              className="carousel-button prev"
            >
              <ChevronLeft size={48} strokeWidth={3} />
            </button>
            <button
              onClick={nextSlide}
              className="carousel-button next"
            >
              <ChevronRight size={48} strokeWidth={3} />
            </button>
          </>
        )}

        {isTinyScreen && (
          <div className="carousel-dots">
            {images.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentIndex ? 'active' : ''}`}
                onClick={() => handleDotClick(index)}
                onKeyPress={(e) => e.key === 'Enter' && handleDotClick(index)}
                role="button"
                tabIndex={0}
                aria-label={`Go to image ${index + 1}`}
              />
            ))}
          </div>
        )}


      </div>

      <div className="carousel-text-div">
        <p className="carousel-text">{parseFormattedText(images[currentIndex].text)}</p>
        <p className="carousel-text-info">{parseFormattedText(images[currentIndex].description)}</p>
        <p className="carousel-text-info ingredients">{parseFormattedText(images[currentIndex].ingredients)}</p>
      </div>

    </div>
  );
};

export default ImageCarousel;