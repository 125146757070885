import React from 'react'
import { Mail, Phone, MapPinHouse } from 'lucide-react';


function Contact({ language }) {
  return (
    <div id="kontakt" className="contact-div">
      <span className="contact-head-small">
        {language === 'CZ' 
        ? "Kontakt"
        : "Contact"}
      </span>
      <span className="contact-head">
        {language === 'CZ' 
        ? "Máte nějaké otázky?"
        : "Do you have any questions?"}
      </span>
      <div className="contact-items">
        <div className="contact-item">
          <Mail />
          <a href="mailto:info@morient.cz" className='contact-link'>
            info@morient.cz
          </a>
        </div>
        <div className='contact-item'>
          <Phone />
          <a href="tel:+420739052244" className='contact-link'>
            +420 739 052 244
          </a>
        </div>
        <div className='contact-item'>
          <Phone />
          <a href="tel:+420777709994" className='contact-link'>
            +420 777 709 994
          </a>
        </div>
        <div className='contact-item contact-item-multiline'>
          <MapPinHouse />
          <div className='contact-item-col'>
            <span>Sídlo společnosti: Dlážděná 503, 353 01 Velká Hleďsebe</span>
            <span>Adresa provozovny: Nádražní 323, 348 13 Chodová Planá</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
