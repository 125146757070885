import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './DbRoute.css'
import { Mail, Phone, Lock, Calendar, Search, Check, Minus, X } from 'lucide-react';
import { databases, getPw } from './appwrite';
import DbRouteDetail from './DbRouteDetail';

function DbRoute() {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(location.state?.isAuthenticated || false);
  const [password, setPassword] = useState('');
  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [pws, setPws] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await databases.listDocuments(
          '66e56789001538be65a2',
          '66e567a0000f222877be'
        );
        
        if (!Array.isArray(response.documents)) {
          throw new Error('Unexpected data format from Appwrite');
        }

        const sortedData = response.documents.sort((a, b) => 
          new Date(b.$createdAt) - new Date(a.$createdAt)
        );
        
        const groupedData = sortedData.reduce((acc, customer) => {
          const date = new Date(customer.$createdAt).toLocaleDateString();
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(customer);
          return acc;
        }, {});

        const pwList = await getPw()
        const passwordList = pwList.documents.map(obj => obj.password);
        setPws(passwordList)
        
        setData(groupedData);
        setFilteredData(groupedData);
        setLoading(false);
      } catch (err) {
        setError(`Error fetching data from Appwrite: ${err.message}`);
        setLoading(false);
        console.error('Error fetching data:', err);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (pws.includes(password)) {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  const applyFilters = () => {
    let filtered = { ...data };

    // Apply date range filter
    if (startDate || endDate) {
      filtered = Object.entries(filtered).reduce((acc, [date, customers]) => {
        const [day, month, year] = date.split('/');
        const currentDate = new Date();
        currentDate.setFullYear(parseInt(year), parseInt(month) - 1, parseInt(day));
        currentDate.setHours(0, 0, 0, 0); // Set time to midnight for accurate comparison
        
        const filterStartDate = startDate ? new Date(startDate) : null;
        const filterEndDate = endDate ? new Date(endDate) : null;
        
        if (filterEndDate) filterEndDate.setHours(23, 59, 59, 999); // Set end date to end of day
        
        if ((!filterStartDate || currentDate >= filterStartDate) &&
            (!filterEndDate || currentDate <= filterEndDate)) {
          acc[date] = customers;
        }
        return acc;
      }, {});
    }

    // Apply search term filter
    if (searchTerm) {
      const lowercaseSearchTerm = searchTerm.toLowerCase();
      filtered = Object.entries(filtered).reduce((acc, [date, customers]) => {
        const filteredCustomers = customers.filter(customer =>
          customer.name.toLowerCase().includes(lowercaseSearchTerm) ||
          customer.email.toLowerCase().includes(lowercaseSearchTerm) ||
          customer.phone.toLowerCase().includes(lowercaseSearchTerm) ||
          customer.address.toLowerCase().includes(lowercaseSearchTerm)
        );
        if (filteredCustomers.length > 0) {
          acc[date] = filteredCustomers;
        }
        return acc;
      }, {});
    }

    setFilteredData(filtered);
  };

  const flavorTranslations = {
    chocolate: "Čokoláda",
    cocoa: "Kakao",
    apple: "Jablko",
    coconut: "Kokos",
    almond: "Mandle",
    hazelnut: "Lískový ořech",
    vanilla: "Vanilka"
  };

  const translateOrderItem = (item) => {
    const [flavor, quantity] = item.split(', ');
    const translatedFlavor = flavorTranslations[flavor.toLowerCase()] || flavor;
    return `${translatedFlavor}, ${quantity}`;
  };

  const getOrderStatus = (customer) => {
    const completedTasks = customer.completed_tasks || [];
    const irreversibleTasks = customer.irreversible_tasks || [];

    // Check if order is completed (last task is done)
    if (completedTasks.includes('Potvrdit odeslání') || 
        irreversibleTasks.includes('Potvrdit odeslání')) {
      return {
        icon: <Check size={24} className="status-icon completed" />,
        label: "Dokončeno"
      };
    }

    // Check if some tasks are completed
    if (completedTasks.length > 0 || irreversibleTasks.length > 0) {
      return {
        icon: <Minus size={24} className="status-icon in-progress" />,
        label: "Zpracovává se"
      };
    }

    // No tasks completed
    return {
      icon: <X size={24} className="status-icon not-started" />,
      label: "Nová"
    };
  };

  const styles = {
    container: {
      padding: '16px',
      marginLeft: '10%',
      width: '80%',
      minHeight: '80vh'
    },
    title: {
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '16px',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    input: {
      flexGrow: 1,
      padding: '8px',
      border: '1px solid #ccc',
      borderRadius: '4px',
    },
    button: {
      backgroundColor: '#3490dc',
      color: 'white',
      padding: '8px 16px',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      marginBottom: '8px',
    },
    filterContainer: {
      display: 'flex',
      gap: '16px',
      marginBottom: '3em',
      marginLeft: '5%'
    },
    filterInput: {
      padding: '8px',
      border: '1px solid #ccc',
      borderRadius: '4px',
    },
  };

  if (isAuthenticated) {
    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;
    if (!filteredData) return <div>No data available</div>;

    return (
      <div className='orders-table'>
        <div style={styles.filterContainer}>
          <div style={styles.inputContainer}>
            <Calendar size={20} />
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              style={styles.filterInput}
            />
          </div>
          <span className='to'>-</span>
          <div style={styles.inputContainer}>
            <Calendar size={20} />
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              style={styles.filterInput}
            />
          </div>
          <div className='string-filter' style={styles.inputContainer}>
            <Search size={20} />
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search..."
              style={styles.filterInput}
            />
          </div>
          <button onClick={applyFilters} style={styles.button}>Hledat</button>
        </div>

        <div className='orders-table-head'>
          <div className='date'>
            <div className='col-head'>Datum zadání</div>
          </div>
          <div className='name'>
            <div className='col-head'>Jméno</div>
          </div>
          <div className='order'>
            <div className='col-head'>Objednávka</div>
          </div>
          <div className='status'>
            <div className='col-head'>Status</div>
          </div>
        </div>
        <div className='line'></div>

        <div className='scrollable'>
          {Object.entries(filteredData).map(([date, customers]) => (
            <div key={date}>
              {Array.isArray(customers) ? (
                customers.map((customer) => (
                  <React.Fragment key={customer.$id}>
                    <Link 
                        to={`/detail/${customer.$id}`}
                        state={{ isAuthenticated: true }}
                        className="detail-link"
                      >
                      <div className='customer-div'>
                        <div className='date'>{date}</div>
                        <div className='name'>{customer.name}</div>
                        <div className='order'>
                          <ul>
                            {Array.isArray(customer.order) ? (
                              customer.order.map((item, index) => (
                                <li key={index}>{translateOrderItem(item)}</li>
                              ))
                            ) : (
                              <li>No order details available</li>
                            )}
                          </ul>
                        </div>
                        <div className='status'>
                          <div className="status-container">
                            {getOrderStatus(customer).icon}
                            <span className="status-label">{getOrderStatus(customer).label}</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className='line-light'></div>
                  </React.Fragment>
                ))
              ) : (
                <div>No customers for this date</div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className='pw-container' style={styles.container}>
      <h1 style={styles.title}>Heslo:</h1>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.inputContainer}>
          <Lock size={20} />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="heslo"
            style={styles.input}
          />
        </div>
        <button type="submit" style={styles.button}>
          Přihlásit
        </button>
      </form>
    </div>
  );
}

export default DbRoute;